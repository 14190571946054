<template>
    <div class="d-grid">
        <button
            class="btn"
            :class="buttonClasses"
            :type="type"
            v-on="$listeners"
            @click="onSubmit"
            :disabled="submitting || disabled"
            :data-testid="dataTestid"
        >
            <img
                v-show="imageSrc"
                class="me-1"
                width="16"
                :src="getImageSrc"
                alt="icon"
            >
            <span
                v-if="submitting"
                class="spinner-border spinner-border-sm"
            />
            <slot v-else-if="!!$slots.default" />
            <span v-else>{{ label }}</span>
        </button>
    </div>
</template>

<script>
    import { logger } from '@/utils/logger'

    export default {
        name: 'FormButton',
        props: {
            label: {
                type: String,
                required: false,
            },
            type: {
                type: String,
                default: 'submit',
            },
            submitting: {
                type: Boolean,
                default: false,
            },
            imageSrc: {
                type: String,
                default: null,
            },
            buttonClasses: {
                type: String,
                default: 'btn btn-primary',
            },
            eventName: {
                type: String,
                default: 'click_button_undefined',
            },
            eventProps: {
                type: Object,
                required: false,
            },
            disabled: {
                type: Boolean,
                required: false,
            },
            dataTestid: {
                type: String,
                required: false,
            },
        },
        computed: {
            getImageSrc() {
                if (!this.imageSrc) {
                    return null
                }

                return require(`../../assets/images/${this.imageSrc}`)
            },
        },
        methods: {
            onSubmit() {
                this.$logEvent(this.eventName, this.eventProps)
                let logMessage = `click button - label: ${this.label} - event name: ${this.eventName}`
                if (this.eventProps) {
                    logMessage += `- event props: ${JSON.stringify(this.eventProps)}`
                }
                logger.info(logMessage)
            },
        },
    }
</script>
