<template>
    <div>
        <validation-provider
            ref="provider"
            :mode="validationMode"
            :rules="validationRules"
            :vid="name"
            v-slot="{ errors }"
        >
            <div class="input">
                <div class="form-floating">
                    <input
                        class="form-control"
                        :type="inputType"
                        :disabled="disabled"
                        :id="name"
                        :name="name"
                        :placeholder="dynamicPlaceholder"
                        :class="`${errors[0] ? 'is-invalid ' : ''}${disabled ? 'text-muted ' : ''}${inputClasses}` || null"
                        :value="value"
                        :autocomplete="autocomplete"
                        :inputmode="inputmode"
                        @click="onClick($event)"
                        @input="onInput($event.target)"
                        @focus="onFocus($event.target)"
                        @blur="onBlur($event.target)"
                    >
                    <label
                        v-if="label"
                        :for="name"
                    >
                        {{ label }}
                    </label>
                    <router-link
                        v-show="inputLinkTo"
                        class="input-link"
                        :to="inputLinkTo"
                        @click.native="$logEvent(inputLinkEvent)"
                    >
                        {{ inputLinkLabel }}
                    </router-link>
                    <button
                        v-if="!!inputOnClick"
                        class="input-link"
                        @click="inputOnClick"
                    >
                        <img
                            src="@/assets/images/global/send.svg"
                            alt="Send"
                        >
                    </button>
                    <span
                        v-show="loading"
                        class="spinner spinner-border text-primary spinner-border-sm align-self-center"
                    />
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
    import { formInputMixin } from '@/mixins/formInputMixin'

    export default {
        name: 'FormField',
        mixins: [formInputMixin],
        props: {
            inputType: {
                type: String,
                default: 'text',
            },
            inputLinkTo: {
                type: String,
                default: '',
            },
            inputLinkLabel: {
                type: String,
                default: '',
            },
            inputLinkEvent: {
                type: String,
                default: '',
            },
            inputOnClick: {
                type: Function,
            },
            inputClasses: {
                type: [String, Object],
            },
        },
        methods: {
            clearErrorMessage() {
                this.applyError()
            },
            applyError(errorMessage) {
                this.$refs.provider.applyResult({
                    errors: [errorMessage], // array of string errors
                    valid: false, // boolean state
                    failedRules: {}, // should be empty since this is a manual error.
                })
            },
            onFocus(eventTarget) {
                this.clearErrorMessage()
                this.$emit('focus', eventTarget.value)
            },
            onClick(event) {
                this.$emit('click', event)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../styles/components/base/formField';
</style>
